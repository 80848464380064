<template>
    <div>
        <v-row>
            <v-col cols="12">
                <Editor :url="url" ref="editor" :fields="fields"
                        icon="mdi-handshake-outline"
                        testo-list="Cruscotto"
                        :args="args"
                        :filters="filters"
                        :no-insert="!$auth('staff')"
                        :no-update="!$auth('staff')"
                        :no-delete="!$auth('staff')"
                >
                    <template #extra>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <div>
                                        <p>
                                            <v-chip small class="mr-2">{{ convenzione.codice }}</v-chip>
                                            {{ convenzione.nome }}<br>
                                            {{ convenzione.tipo }}<br>
                                            {{ convenzione.tipo_attivita }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </Editor>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import Editor from "@/components/Editor";
import {mapState} from "vuex";

export default {
    data: () => ({
        url: '/audit/cruscotto/',
        fields: [
            {text: 'Lotto', value: 'codice_lotto'},
            {text: 'Fornitore', value: 's_fornitore.last_name'},
            {text: 'Campione', value: 'campione'},
            {text: 'Totale Ispezioni Validate', value: 'validate'},
            {text: 'NCL', value: 'ncl'},
            {text: 'NCI', value: 'nci'},
            {text: 'NCG', value: 'ncg'},
            {text: 'Ordini Conformi (5-4)', value: 'ispezioni_conformi'},
            {text: 'Ordini con almeno una Non Conformità Lieve - Importante (3-2)', value: 'ispezioni_ncg_nci'},
            {text: 'Ordini con almeno una Non Conformità Grave (1)', value: 'ispezioni_ncg'},
            {text: '% Ispezioni con NCG gravi', value: 'perc_ispezioni_ncg'},
        ],
        convenzione: {},
    }),
    async mounted() {
        await this.loadConvenzione();
    },
    computed: {
        ...mapState(['dataset']),
        args() {
            return {convenzione: this.$route.params.id};
        },
        filters() {
            return [
                {field: 'codice_lotto', label: 'Lotto'},
                {
                    field: 'fornitore',
                    label: 'Fornitore',
                    type: 'ajax',
                    url: '/backend/autocomplete/userprofile',
                    filter: {ruoli: 'fornitore', convenzione_id: this.$route.params.id}
                },
            ]
        },
    },
    methods: {
        async loadConvenzione() {
            const id = this.$route.params.id;
            const res = await this.$http.get(`/audit/convenzione/${id}/`);
            if (res.ok) {
                this.convenzione = res.result.data;
            }
        },
    },
    components: {
        Editor,
    },
}
</script>